import { LogLevel } from '@azure/msal-browser';
import { isEdge, isFirefox, isIE, isSafari } from '@shared/constants';

export type AuthSettings = {
    clientId: string;
    authority: string;
    redirectUri: string;
    postLogoutRedirectUri: string;
    scopes: string[];
};

export type LinkSettings = {
    usageGuidance: string;
    tokenCalculation: string;
    modelsGuidance: string;
    nesGPTDocs: string;
    teamsBotRedirection: string;
    talkToMyDocumentsGuidance: string;
};

export type FileSettings = {
    size: string;
    types: string;
    number: string;
};

export type InsightsSettings = {
    key: string;
};

export type WalkMeSettings = {
    url: string;
};

type GoogleAnalyticsSettings = {
    id: string;
};

export type FeatureFlags = {
    unifiedBots: boolean;
    streaming: boolean;
    nesgptAvailable: boolean;
    feedbackShare: boolean;
    typeWriterDisabled: boolean;
    promptLibrary: boolean;
};

export type Settings = {
    auth: AuthSettings;
    links: LinkSettings;
    fileSettings: FileSettings;
    appInsight: InsightsSettings;
    googleAnalytics: GoogleAnalyticsSettings;
    walkMe: WalkMeSettings;
    featureFlags: FeatureFlags;
};

export const msalSettings = (settings: AuthSettings) => ({
    auth: {
        clientId: settings.clientId,
        authority: settings.authority,
        redirectUri: settings.redirectUri,
        postLogoutRedirectUri: settings.postLogoutRedirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE || isEdge || isFirefox || isSafari,
    },
    loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
            }
        },
    },
});

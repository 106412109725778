import { IPublicClientApplication } from '@azure/msal-browser';
import { Settings } from '@features/appSettings';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { useStore } from '@store';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ClientSideNavigation } from './ClientSideNavigation';
import './assets/styles/global.scss';
import './assets/styles/scrollbar.scss';
import './assets/styles/typography.scss';
import { configureAppInsights } from './configureAppInsights';
import { ErrorPage } from './pages';
import { ApplicationRoutes } from './router';
import { useQueryClient } from './useQueryClient';
import { useHostedInTeams } from '@features/teams/useHostedInTeams';

type Props = {
    publicClientApplication: IPublicClientApplication;
    settings: Settings;
};

const App = ({ publicClientApplication, settings }: Props) => {
    const setLinkSettings = useStore(state => state.links.setLinkSettings);
    const setFileSettings = useStore(state => state.fileSettings.setFileSettings);
    const setKey = useStore(state => state.insights.setKey);
    const setFeatureFlags = useStore(state => state.featureFlags.setFeatureFlags);

    const { queryClient } = useQueryClient();

    const { reactPlugin, appInsights } = configureAppInsights(settings.appInsight.key);
    useHostedInTeams(appInsights);

    useEffect(() => {
        setLinkSettings(settings.links);
        setFileSettings(settings.fileSettings);
        setKey(settings.appInsight.key);
        setFeatureFlags(settings.featureFlags);
    }, []);

    return (
        <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
            <AppInsightsContext.Provider value={reactPlugin}>
                <Router>
                    <ClientSideNavigation publicClientApplication={publicClientApplication}>
                        <QueryClientProvider client={queryClient()}>
                            <ApplicationRoutes authSettings={settings.auth} />
                            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                        </QueryClientProvider>
                    </ClientSideNavigation>
                </Router>
            </AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
    );
};

export { App };

import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    chatQuestion: {
        text: string;
        setText: (questionText: string) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    chatQuestion: {
        text: '',
        setText: (text: string) =>
            set(
                produce((draft: Slice) => {
                    draft.chatQuestion.text = text;
                })
            ),
    },
});

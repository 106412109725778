import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { transitionalAuthStatus } from '@features/authentication/domain';
import { checkPermission } from '@features/authorization';

const isAuthenticating = (status: InteractionStatus, isLoadingMeInfo: boolean) =>
    transitionalAuthStatus.includes(status) && isLoadingMeInfo;

const isNotAuthenticated = (status: InteractionStatus, accounts: AccountInfo[], isAuthenticated: boolean) =>
    !transitionalAuthStatus.includes(status) && accounts.length === 0 && !isAuthenticated;

const isAuthenticatedCompleted = (
    status: InteractionStatus,
    accounts: AccountInfo[],
    isAuthenticated: boolean,
    permissions: string[],
    isLoadingMeInfo: boolean
) =>
    !transitionalAuthStatus.includes(status) &&
    accounts.length > 0 &&
    isAuthenticated &&
    checkPermission(permissions) &&
    !isLoadingMeInfo;

const isAuthenticatedWithoutPermissions = (
    accounts: AccountInfo[],
    isAuthenticated: boolean,
    permissions: string[],
    isLoadingMeInfo: boolean
) => accounts.length > 0 && isAuthenticated && !checkPermission(permissions) && !isLoadingMeInfo;

export { isAuthenticatedCompleted, isAuthenticatedWithoutPermissions, isAuthenticating, isNotAuthenticated };

import { produce } from 'immer';
import { NIL as NIL_UUID } from 'uuid';
import { StateCreator } from 'zustand';
import { AssistantIdentifier, KindType } from '../domain';

export type Slice = {
    newConversation: {
        kind: KindType;
        id: string;
        assistantIds?: AssistantIdentifier[];
        isNew: boolean;
        setNewConversation: (id: string, kind: KindType, assistantIds?: AssistantIdentifier[]) => void;
        setOldConversation: (id: string) => void;
        resetStore: () => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    newConversation: {
        kind: 'standard',
        id: NIL_UUID,
        isNew: false,
        setNewConversation: (id: string, kind: KindType, assistantIds?: AssistantIdentifier[]) =>
            set(
                produce((draft: Slice) => {
                    draft.newConversation.id = id;
                    draft.newConversation.kind = kind;
                    draft.newConversation.isNew = true;

                    if (assistantIds) {
                        draft.newConversation.assistantIds = assistantIds;
                    }
                })
            ),
        setOldConversation: (id: string) =>
            set(
                produce((draft: Slice) => {
                    draft.newConversation.id = id;
                    draft.newConversation.isNew = false;
                })
            ),
        resetStore: () =>
            set(
                produce((draft: Slice) => {
                    draft.newConversation.id = NIL_UUID;
                    draft.newConversation.kind = 'standard';
                    draft.newConversation.isNew = false;
                })
            ),
    },
});

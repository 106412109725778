import { ButtonHTMLAttributes, forwardRef, Ref } from 'react';
import './button.scss';

const ButtonComponent = (
    { children, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>,
    ref: Ref<HTMLButtonElement>
) => (
    <button ref={ref} {...rest}>
        {children}
    </button>
);
const Button = forwardRef(ButtonComponent);

export { Button };

import { useStore } from '@store';

export const useVersion = () => {
    const version = useStore(state => state.versioning.version);
    const setIsVersionChanged = useStore(state => state.versioning.setIsVersionChanged);

    const updateCacheVersionFromStore = () => {
        sessionStorage.setItem('version', version);
        setIsVersionChanged(false);
    };

    return {
        updateCacheVersion: updateCacheVersionFromStore,
    };
};

import { Helmet } from 'react-helmet-async';

type Props = {
    url: string;
};

const WalkMe = ({ url }: Props) => (
    <Helmet>
        <script type="text/javascript">{`(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript';
        walkme.async = true; walkme.src = "${url}"; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s);
        window._walkmeConfig = {smartLoad:true}; })();`}</script>
    </Helmet>
);

export { WalkMe };

import './skeletonLoader.scss';
import { NesGptLogo } from '../../nesgptLogo';
import { isTodayNesGptAnniversary } from '@shared/ui/nesgptLogo/domain';
import { useStore } from '@store';

const SkeletonLoader = () => {
    const selectedAssistant = useStore(state => state.assistants.selectedAssistant);
    const isUnifiedBotsEnabled = useStore(store => store.featureFlags.flags.unifiedBots);

    return (
        <div className="svg-container" data-testid="skeleton-loader">
            <NesGptLogo
                logoSize="extra-large"
                className="svg-container__logo"
                color="text-primary-400"
                variant={isTodayNesGptAnniversary() ? 'anniversary' : 'default'}
                text={isUnifiedBotsEnabled ? selectedAssistant?.name : undefined}
            />
        </div>
    );
};

export { SkeletonLoader };

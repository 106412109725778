import { useTranslation } from 'react-i18next';
import { ReactComponent as NestleLogoNoWord } from '@assets/images/nestleNoWord.svg';
import classNames from 'classnames';
import { NesGptLogoSize } from '@shared/ui/nesgptLogo/domain';
import './nesGptLogo.scss';

type Props = NesGptLogoAnniversaryProps | NesGptLogoProps;

type NesGptLogoProps = {
    className?: string;
    color?: string;
    logoSize?: `${NesGptLogoSize}`;
    variant?: 'default';
    text?: string;
};

type NesGptLogoAnniversaryProps = {
    variant?: 'anniversary';
    showAnniversaryLabel?: boolean;
} & Omit<NesGptLogoProps, 'variant'>;

const NesGptLogo = ({
    className = '',
    logoSize = NesGptLogoSize.LARGE,
    color = 'text-primary',
    text,
    ...props
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={`flex flex-col justify-center items-center ${className}`}>
            <NestleLogoNoWord
                className={classNames(color, {
                    'w-24 h-16': logoSize === NesGptLogoSize.LARGE,
                    'h-9 2xl:h-[70px]': logoSize === NesGptLogoSize.SMALL,
                    'w-[176px] h-[130px]': logoSize === NesGptLogoSize.EXTRA_LARGE,
                })}
            />
            <div
                className={classNames('flex flex-col gap-[10px] w-full', {
                    'mt-5': logoSize === NesGptLogoSize.EXTRA_LARGE && props.variant === 'default',
                    'mt-3': logoSize === NesGptLogoSize.EXTRA_LARGE && props.variant === 'anniversary',
                })}
            >
                <span
                    className={classNames(color, 'nesgpt-logo__text', {
                        'text-2xl': logoSize === NesGptLogoSize.LARGE,
                        'text-base 2xl:text-3xl': logoSize === NesGptLogoSize.SMALL,
                        'text-[52px] leading-[68px] md:leading-9':
                            logoSize === NesGptLogoSize.EXTRA_LARGE && props.variant === 'default',
                        'text-[49px]': logoSize === NesGptLogoSize.EXTRA_LARGE && props.variant === 'anniversary',
                    })}
                >
                    {text || t('app.name')}
                </span>

                {props.variant === 'anniversary' ? (
                    <div className={'overflow-hidden relative'}>
                        <span
                            id={'animation-text'}
                            data-testid={'animation-text'}
                            className={classNames(color, 'text-[34px] absolute transition-all duration-1000', {
                                'translate-y-[-50px]': !props.showAnniversaryLabel,
                            })}
                        >
                            {t('app.anniversary')}
                        </span>
                        <span className={classNames(color, 'text-[34px] invisible')}>{t('app.anniversary')}</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export { NesGptLogo };

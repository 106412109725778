import { AccountInfo, InteractionStatus } from '@azure/msal-browser';

export const transitionalAuthStatus = [InteractionStatus.HandleRedirect, InteractionStatus.Startup];

export type AuthRequest = { scopes: string[] };

export type User = {
    account: AccountInfo | null;
    accessToken: string;
    authRequest: AuthRequest;
    userPhoto: string;
    permissions: string[];
};

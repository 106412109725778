import { ErrorPageResources, ErrorPageVariant } from '@pages/domain';
import { NesGptLogo } from '@shared/ui';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

type Props = {
    variant?: ErrorPageVariant;
};

const ErrorPage = ({ variant = 'default' }: Props) => {
    const { t } = useTranslation();

    const resources = ErrorPageResources[variant];
    const Icon = resources.icon;
    const CtaIcon = resources.ctaIcon;

    return (
        <>
            <MobileView>
                <div className="w-full h-screen flex flex-col justify-center items-center text-center">
                    <div className="w-full flex justify-center">
                        <Icon className="w-[200px] h-[200px]" />
                    </div>
                    <div className="p-4">
                        <h2 className="text-[70px] text-primary-400 leading-[98px] font-bold mb-4">
                            {t('errorPage.warning')}
                        </h2>
                        <h3 className="text-2xl mb-2 font-bold">{t(resources.title)}</h3>
                        <p className="text-xl mb-11" data-testid="error-page-text-content">
                            {t(resources.subtitle)}
                        </p>
                        <a
                            href={typeof resources.ctaLink === 'string' ? resources.ctaLink : resources.ctaLink()}
                            className="btn btn-md min-w-4/6"
                        >
                            <span className="text-xl">{t(resources.ctaText)}</span>
                            <span>{CtaIcon ? <CtaIcon className="w-4 h-4" /> : null}</span>
                        </a>
                    </div>
                </div>
            </MobileView>
            <BrowserView>
                <div className="w-full h-screen flex flex-col justify-center items-center">
                    <NesGptLogo className="mb-12" />
                    <div className="flex justify-start items-center gap-6 max-w-2xl">
                        <div className="w-1/2">
                            <h2 className="text-[108px] text-primary-400 leading-[98px] font-bold mb-4">
                                {t('errorPage.warning')}
                            </h2>
                            <h3 className="text-2xl mb-2 font-bold">{t(resources.title)}</h3>
                            <p className="text-xl mb-11" data-testid="error-page-text-content">
                                {t(resources.subtitle)}
                            </p>
                            <a
                                href={typeof resources.ctaLink === 'string' ? resources.ctaLink : resources.ctaLink()}
                                className="btn btn-md min-w-4/6"
                            >
                                <span className="text-xl">{t(resources.ctaText)}</span>
                                <span>{CtaIcon ? <CtaIcon className="w-4 h-4" /> : null}</span>
                            </a>
                        </div>
                        <div className="w-1/2">
                            <Icon />
                        </div>
                    </div>
                </div>
            </BrowserView>
        </>
    );
};

export { ErrorPage };

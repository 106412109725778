import { AssistantIdentifier } from '@features/chat/domain';
import {
    Assistant,
    AssistantConfiguration,
    AssistantPreview,
    AssistantPreviewPromptingGuide,
    PromptSuggestionTagType,
    PromptSuggestionVariantType,
} from '@features/assistants/domain';
import { StateCreator } from 'zustand';
import { produce } from 'immer';

export type Slice = {
    assistants: {
        list: AssistantPreview[];
        selectedAssistant: {
            isLoadingAssistant: boolean;
            assistantId: AssistantIdentifier;
            assistantProviderId: string;
            name: string;
            description: string;
            oneLinerDescription: string;
            onboardingText: string;
            imageUrl: string;
            supportLink: string;
            supportText: string;
            privacyPolicyLink: string;
            knowledgeBase: { link: string; text: string }[];
            category: string;
            configuration: AssistantConfiguration;
            promptGuide: AssistantPreviewPromptingGuide[];
        };
        setIsLoadingAssistant: (value: boolean) => void;
        setSelectedAssistant: (assistant: Assistant) => void;
        setSelectedAssistantName: (name: string) => void;
        setAssistants: (assistants: AssistantPreview[]) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    assistants: {
        list: [],
        selectedAssistant: {
            isLoadingAssistant: false,
            assistantId: { id: '', providerId: '', name: '' },
            assistantProviderId: '',
            name: '',
            description: '',
            oneLinerDescription: '',
            onboardingText: '',
            imageUrl: '',
            supportLink: '',
            supportText: '',
            privacyPolicyLink: '',
            knowledgeBase: [{ link: '', text: '' }],
            category: '',
            configuration: {
                isChatWithYourDocumentsAllowed: false,
                temperaturesAllowed: [''],
                modelsAllowed: [
                    {
                        service: 'AzChatGpt',
                        maxTokens: 0,
                    },
                ],
                embeddingMethod: '',
                assistantPrompt: '',
                metadataFormat: '',
            },
            promptGuide: [
                {
                    id: '',
                    title: '',
                    suggestions: [
                        {
                            title: '',
                            text: '',
                            icon: '',
                            tagType: PromptSuggestionTagType.internet,
                            variant: PromptSuggestionVariantType.tag,
                        },
                    ],
                },
            ],
        },
        setIsLoadingAssistant: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.assistants.selectedAssistant.isLoadingAssistant = value;
                })
            ),
        setSelectedAssistant: (assistant: Assistant) => {
            set(
                produce((draft: Slice) => {
                    draft.assistants.selectedAssistant.assistantId.id = assistant.assistantId;
                    draft.assistants.selectedAssistant.assistantId.providerId = assistant.assistantProviderId;
                    draft.assistants.selectedAssistant.assistantProviderId = assistant.assistantProviderId;
                    draft.assistants.selectedAssistant.name = assistant.name;
                    draft.assistants.selectedAssistant.description = assistant.description;
                    draft.assistants.selectedAssistant.oneLinerDescription = assistant.oneLinerDescription;
                    draft.assistants.selectedAssistant.onboardingText = assistant.onboardingText;
                    draft.assistants.selectedAssistant.imageUrl = assistant.imageUrl;
                    draft.assistants.selectedAssistant.supportLink = assistant.supportLink;
                    draft.assistants.selectedAssistant.privacyPolicyLink = assistant.privacyPolicyLink;
                    draft.assistants.selectedAssistant.knowledgeBase = assistant.knowledgeBase;
                    draft.assistants.selectedAssistant.category = assistant.category;
                    draft.assistants.selectedAssistant.configuration = assistant.configuration;
                    draft.assistants.selectedAssistant.promptGuide = assistant.promptGuide;
                })
            );
        },
        setAssistants: (assistants: AssistantPreview[]) => {
            set(
                produce((draft: Slice) => {
                    draft.assistants.list = assistants;
                })
            );
        },
        setSelectedAssistantName: (name: string) => {
            set(
                produce((draft: Slice) => {
                    draft.assistants.selectedAssistant.name = name;
                })
            );
        },
    },
});

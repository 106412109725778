import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { HistoryConversation } from '@features/chat/domain';

type HistoryChatSlice = {
    historyChat: {
        hasMoreChatsToLoad: boolean;
        pinned: HistoryConversation[];
        unpinned: HistoryConversation[];
        setPinnedChats: (chats: HistoryConversation[]) => void;
        addToUnpinned: (chats: HistoryConversation[]) => void;
        setUnpinnedChats: (chats: HistoryConversation[]) => void;
        moveChat: (conversationId: string, toPinned: boolean) => void;
        rollbackChatMove: (prevState: { pinned: HistoryConversation[]; unpinned: HistoryConversation[] }) => void;
        setChats: (pinned: HistoryConversation[], unpinned: HistoryConversation[]) => void;
        deleteChat: (conversationId: string) => void;
        setHasMoreChatsToLoad: (value: boolean) => void;
    };
};

const createSlice: StateCreator<HistoryChatSlice, [], [], HistoryChatSlice> = set => ({
    historyChat: {
        pinned: [],
        unpinned: [],
        hasMoreChatsToLoad: true,
        setHasMoreChatsToLoad: (value: boolean) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.hasMoreChatsToLoad = value;
                })
            ),
        setPinnedChats: (chats: HistoryConversation[]) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.pinned = chats;
                })
            ),
        setUnpinnedChats: (chats: HistoryConversation[]) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.unpinned = chats;
                })
            ),
        addToUnpinned: (chats: HistoryConversation[]) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.unpinned = draft.historyChat.unpinned
                        .concat(chats)
                        .filter(
                            (item, index, self) =>
                                index === self.findIndex(t => t.conversationId === item.conversationId)
                        );
                })
            ),
        moveChat: (conversationId: string, toPinned: boolean) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    let chatToMove;
                    if (toPinned) {
                        chatToMove = draft.historyChat.unpinned.find(chat => chat.conversationId === conversationId);

                        if (chatToMove) {
                            chatToMove.pinned = true;
                            draft.historyChat.unpinned = draft.historyChat.unpinned.filter(
                                chat => chat.conversationId !== conversationId
                            );
                            draft.historyChat.pinned.unshift(chatToMove);
                        }
                    } else {
                        chatToMove = draft.historyChat.pinned.find(chat => chat.conversationId === conversationId);

                        if (chatToMove) {
                            chatToMove.pinned = false;
                            draft.historyChat.pinned = draft.historyChat.pinned.filter(
                                chat => chat.conversationId !== conversationId
                            );
                            draft.historyChat.unpinned.unshift(chatToMove);
                        }
                    }
                })
            ),
        rollbackChatMove: (prevState: { pinned: HistoryConversation[]; unpinned: HistoryConversation[] }) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.pinned = prevState.pinned;
                    draft.historyChat.unpinned = prevState.unpinned;
                })
            ),
        setChats: (pinned: HistoryConversation[], unpinned: HistoryConversation[]) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.pinned = pinned;
                    draft.historyChat.unpinned = unpinned;
                })
            ),
        deleteChat: (conversationId: string) =>
            set(
                produce((draft: HistoryChatSlice) => {
                    draft.historyChat.pinned = draft.historyChat.pinned.filter(
                        chat => chat.conversationId !== conversationId
                    );
                    draft.historyChat.unpinned = draft.historyChat.unpinned.filter(
                        chat => chat.conversationId !== conversationId
                    );
                })
            ),
    },
});

export { createSlice, type HistoryChatSlice };

export const checkPermission = (permissions: string[], requiredPermission?: string[]) => {
    if (!permissions || permissions.length === 0) {
        return false;
    }

    if (!requiredPermission || requiredPermission.length === 0) {
        return true;
    }

    return requiredPermission.every(requiredPermission => permissions.includes(requiredPermission));
};

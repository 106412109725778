import { IPublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { executeMeInfo, executeTokenAcquisition } from '@features/authentication';
import { User } from '@features/authentication/domain';
import { useStore } from '@store';
import { QueryClient } from '@tanstack/react-query';

const retryDelay = (retryCount: number) => (retryCount === 0 ? 1000 : 3000);

type Props = {
    failureCount: number;
    error: Error;
    instance: IPublicClientApplication;
    isAuthenticated: boolean;
    user: User;
    setUser: (user: User) => void;
};

const retryOnFailure = ({ failureCount, error, instance, isAuthenticated, user, setUser }: Props) => {
    if (error.cause === 401 && failureCount <= 2 && isAuthenticated) {
        acquireTokenAsync(instance, user, setUser);
        return true;
    }
    return false;
};

const acquireTokenAsync = async (instance: IPublicClientApplication, user: User, setUser: (user: User) => void) => {
    const tokenAcquisition = await executeTokenAcquisition(instance, user.authRequest);
    setUser({
        ...user,
        accessToken: tokenAcquisition?.accessToken || '',
        account: tokenAcquisition?.account || null,
    });

    const meInfo = await executeMeInfo();
    setUser({
        ...user,
        accessToken: tokenAcquisition?.accessToken || '',
        account: tokenAcquisition?.account || null,
        userPhoto: meInfo?.userPhoto || '',
        permissions: meInfo?.permissions || [],
    });
};

export const useQueryClient = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { user, setUser } = useStore(state => state.auth);

    const queryClient = () =>
        new QueryClient({
            defaultOptions: {
                queries: {
                    suspense: true,
                    refetchOnWindowFocus: false,
                    useErrorBoundary: true,
                    retry: (failureCount, error) =>
                        retryOnFailure({
                            failureCount,
                            error: error as Error,
                            instance,
                            isAuthenticated,
                            user,
                            setUser,
                        }),
                    retryDelay: retryCount => retryDelay(retryCount),
                },
                mutations: {
                    retry: (failureCount, error) =>
                        retryOnFailure({
                            failureCount,
                            error: error as Error,
                            instance,
                            isAuthenticated,
                            user,
                            setUser,
                        }),
                    retryDelay: retryCount => retryDelay(retryCount),
                },
            },
        });

    return { queryClient };
};

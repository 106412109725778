import { useStore } from '@store';
import { produce } from 'immer';
import { configureAppInsights } from 'src/configureAppInsights';
import { v4 as uuidv4 } from 'uuid';
import { ManageCurrentVersion } from './domain';

const http = async <T>(path: string, config: RequestInit): Promise<T> => {
    const idToken = useStore.getState().auth.user.accessToken;
    const userName = useStore.getState().auth.user.account?.username;
    const appInsightKey = useStore.getState().insights.key;
    const callIdentifier = uuidv4();

    const { appInsights } = configureAppInsights(appInsightKey || '');
    appInsights.trackEvent(
        { name: 'http_init' },
        {
            headerIdentifier: `X-Fetch-Site: ${callIdentifier}`,
            method: config.method,
            path: path,
            userName: userName,
        }
    );

    const secureConfig = idToken
        ? produce(config, draft => {
              draft.headers = { ...draft.headers, Authorization: `Bearer ${idToken}`, 'X-Fetch-Site': callIdentifier };
          })
        : config;

    const request = new Request(path, secureConfig);
    const response = await fetch(request);

    ManageCurrentVersion(response);

    if (!response.ok) {
        const responseJson = response.json();
        appInsights.trackEvent(
            { name: 'http_error' },
            {
                headerIdentifier: `X-Fetch-Site: ${callIdentifier}`,
                method: config.method,
                path: path,
                userName: userName,
                statusCode: response.status,
                cause: responseJson,
            }
        );
        switch (response.status) {
            case 401:
                throw new Error(response.statusText, {
                    cause: response.status,
                });
            case 406:
                throw new Error('contentFilter', {
                    cause: await responseJson,
                });
            case 409:
                throw new Error('conflict', {
                    cause: await responseJson,
                });
            case 413:
                throw new Error('maximumContent', {
                    cause: await responseJson,
                });
            case 429:
                throw new Error('tooManyRequests', {
                    cause: await responseJson,
                });
            default:
                throw new Error('errorMessageGenericAction', {
                    cause: response.status,
                });
        }
    }
    return response.json().catch(() => {});
};

const defaultConfig: RequestInit = {
    headers: {
        'Content-Type': 'application/json',
    },
};

export type Agent = {
    get: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    post: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    put: <T, U>(path: string, body: T, signal?: AbortSignal) => Promise<U>;
    delete: <T>(path: string, signal?: AbortSignal) => Promise<T>;
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal) => Promise<U>;
};

export const agent: Agent = {
    get: <T>(path: string, signal?: AbortSignal): Promise<T> =>
        http<T>(path, { method: 'GET', ...defaultConfig, signal: signal }),
    post: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'POST', ...defaultConfig, body: JSON.stringify(body), signal: signal }),
    postFormData: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'POST', ...defaultConfig, body: body as BodyInit, signal: signal, headers: {} }),
    put: <T, U>(path: string, body: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, { method: 'PUT', body: JSON.stringify(body), ...defaultConfig, signal: signal }),
    delete: <T>(path: string, signal?: AbortSignal): Promise<T> =>
        http<T>(path, { method: 'DELETE', ...defaultConfig, signal: signal }),
    patch: <T, U>(path: string, body?: T, signal?: AbortSignal): Promise<U> =>
        http<U>(path, {
            ...defaultConfig,
            method: 'PATCH',
            body: body ? JSON.stringify(body) : JSON.stringify({}),
            signal: signal,
        }),
};

import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { User } from './domain';

export type Slice = {
    auth: {
        setUser: (user: User) => void;
        user: User;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    auth: {
        user: { account: null, accessToken: '', authRequest: { scopes: [] }, userPhoto: '', permissions: [] },
        setUser: (user: User) =>
            set(
                produce((draft: Slice) => {
                    draft.auth.user = user;
                })
            ),
    },
});

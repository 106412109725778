import { app, HostName } from '@microsoft/teams-js';

// Ensure that the Teams SDK is initialized once no matter how often this is called
let teamsInitPromise: Promise<void>;

export const ensureTeamsSdkInitialized = () => {
    if (!teamsInitPromise) {
        teamsInitPromise = app.initialize();
    }
    return teamsInitPromise;
};

export const inTeams = async () => {
    try {
        await ensureTeamsSdkInitialized();
        const context = await app.getContext();
        const hostName = context.app.host.name;

        return hostName === HostName.teams || hostName === HostName.teamsModern;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.info(`Unable to determine whether the app is running in Teams. Details: ${error}`);
        return false;
    }
};

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { handleLogin } from '@features/authentication';
import { LazyGuestPage, LazyPublicLandingPage } from '@pages/lazyLoadingPages';
import { useStore } from '@store';
import { Outlet } from 'react-router-dom';
import {
    isAuthenticatedCompleted,
    isAuthenticatedWithoutPermissions,
    isAuthenticating,
    isNotAuthenticated,
} from './domain';

type Props = {
    scopes: string[];
    isLoadingMeInfo: boolean;
};

const RouteGuard = ({ scopes, isLoadingMeInfo }: Props) => {
    const { inProgress, instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { isHostedInTeams } = useStore(state => state.teams);
    const permissions = useStore(state => state.auth.user.permissions);

    if (isHostedInTeams === null || (isHostedInTeams && isLoadingMeInfo)) {
        return <></>;
    }

    if (isHostedInTeams) {
        return <Outlet />;
    }

    if (isAuthenticating(inProgress, isLoadingMeInfo)) {
        return <></>;
    } else if (isNotAuthenticated(inProgress, accounts, isAuthenticated)) {
        return <LazyPublicLandingPage loginHandler={() => handleLogin({ instance, scopes })} />;
    } else if (isAuthenticatedCompleted(inProgress, accounts, isAuthenticated, permissions, isLoadingMeInfo)) {
        return <Outlet />;
    } else if (isAuthenticatedWithoutPermissions(accounts, isAuthenticated, permissions, isLoadingMeInfo)) {
        return <LazyGuestPage />;
    } else {
        return <></>;
    }
};

export { RouteGuard };

import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    versioning: {
        isVersionChanged: boolean;
        version: string;
        setVersion: (version: string) => void;
        setIsVersionChanged: (version: boolean) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    versioning: {
        isVersionChanged: false,
        setIsVersionChanged: (hasChanged: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.versioning.isVersionChanged = hasChanged;
                })
            ),
        version: '',
        setVersion: (version: string) =>
            set(
                produce((draft: Slice) => {
                    draft.versioning.version = version;
                })
            ),
    },
});

import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { AssistantIdentifier } from '@features/chat/domain';

type FilterSlice = {
    filter: {
        pagination: {
            start: number;
            count: number;
        };
        assistantIds: AssistantIdentifier[];
        lastUpdateTo: Date | null;
        lastUpdateFrom: Date | null;
        isPinned: boolean;
        setPagination: (start?: number, count?: number) => void;
        setSelectedFilters: (
            assistantIds: AssistantIdentifier[],
            lastUpdateTo?: Date | null,
            lastUpdateFrom?: Date | null
        ) => void;
    };
};

const localLastUpdateTo = new Date();
localLastUpdateTo.setMinutes(new Date().getMinutes() - 1);

const createSlice: StateCreator<FilterSlice, [], [], FilterSlice> = set => ({
    filter: {
        pagination: {
            start: 0,
            count: 10,
        },
        assistantIds: [],
        lastUpdateFrom: null,
        lastUpdateTo: null,
        isPinned: false,

        setPagination: (start = 0, count = 10) =>
            set(
                produce((draft: FilterSlice) => {
                    draft.filter.pagination.start = start;
                    draft.filter.pagination.count = count;
                })
            ),

        setSelectedFilters: (
            assistantIds: AssistantIdentifier[],
            lastUpdateTo: Date | null = localLastUpdateTo,
            lastUpdateFrom: Date | null = null
        ) =>
            set(
                produce((draft: FilterSlice) => {
                    draft.filter.assistantIds = assistantIds;
                    draft.filter.lastUpdateFrom = lastUpdateFrom || null;
                    draft.filter.lastUpdateTo = lastUpdateFrom ? lastUpdateTo : null;
                    draft.filter.pagination.start = 0;
                    draft.filter.pagination.count = 10;
                })
            ),
    },
});

export { createSlice, type FilterSlice };

import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { BarSection } from './domain';
import { ColorSettings } from '@shared/styles';

type MetadataTitle = {
    text: string;
    link: string;
    secondaryText: string;
    type: ColorSettings;
};

type Slice = {
    chatSideBar: {
        isSidebarContentExpanded: boolean;
        toolBarSectionSelected: BarSection;
        setToolBarExpanded: (value: boolean) => void;
        setToolBarSectionSelected: (value: BarSection) => void;
        closeAndClearToolBar: () => void;
        isSidebarExpanded: boolean;
        setListSectionExpanded: (value: boolean) => void;
    };
    rightSideBar: {
        isExpanded: boolean;
        metadata: {
            title: MetadataTitle;
            content: string;
        };
        setMetadata: (isExpanded: boolean, title: MetadataTitle, content: string) => void;
    };
    chatPromptingGuide: {
        isOpen: boolean;
        setIsOpen: (value: boolean) => void;
        activeTab?: string;
        setActiveTab: (tab?: string) => void;
    };
};

const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    chatSideBar: {
        isSidebarContentExpanded: false,
        toolBarSectionSelected: '',
        setToolBarExpanded: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.chatSideBar.isSidebarContentExpanded = value;
                    if (value && draft.rightSideBar.isExpanded) {
                        draft.rightSideBar.isExpanded = false;
                        draft.rightSideBar.metadata = {
                            title: {
                                text: '',
                                secondaryText: '',
                                link: '',
                                type: 'secondary',
                            },
                            content: '',
                        };
                    }
                })
            ),
        setToolBarSectionSelected: (value: BarSection) =>
            set(
                produce((draft: Slice) => {
                    draft.chatSideBar.toolBarSectionSelected = value;
                })
            ),
        closeAndClearToolBar: () =>
            set(
                produce((draft: Slice) => {
                    draft.chatSideBar.toolBarSectionSelected = '';
                    draft.chatSideBar.isSidebarContentExpanded = false;
                })
            ),
        isSidebarExpanded: false,
        setListSectionExpanded: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.chatSideBar.isSidebarExpanded = value;
                    if (value && draft.rightSideBar.isExpanded) {
                        draft.rightSideBar.isExpanded = false;
                        draft.rightSideBar.metadata = {
                            title: {
                                text: '',
                                secondaryText: '',
                                link: '',
                                type: 'secondary',
                            },
                            content: '',
                        };
                    }
                })
            ),
    },
    rightSideBar: {
        isExpanded: false,
        metadata: {
            title: {
                text: '',
                secondaryText: '',
                link: '',
                type: 'secondary',
            },
            content: '',
            titleNumeration: '',
        },
        setMetadata: (isExpanded: boolean, title: MetadataTitle, content: string) =>
            set(
                produce((draft: Slice) => {
                    draft.rightSideBar.isExpanded = isExpanded;
                    draft.rightSideBar.metadata.title = title;
                    draft.rightSideBar.metadata.content = content;
                    if (isExpanded && draft.chatSideBar.isSidebarContentExpanded) {
                        draft.chatSideBar.isSidebarContentExpanded = false;
                        draft.chatSideBar.toolBarSectionSelected = '';
                    }
                })
            ),
    },
    chatPromptingGuide: {
        isOpen: false,
        setIsOpen: (value: boolean) => {
            set(
                produce((draft: Slice) => {
                    draft.chatPromptingGuide.isOpen = value;
                })
            );
        },
        activeTab: '',
        setActiveTab: (tab?: string) => {
            set(
                produce((draft: Slice) => {
                    draft.chatPromptingGuide.activeTab = tab;
                })
            );
        },
    },
});

export { createSlice, type Slice };

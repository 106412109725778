import { produce } from 'immer';
import { StateCreator } from 'zustand';

type Slice = {
    chatPageModals: {
        showModalNewDocumentChat: boolean;
        showModalActiveSession: boolean;
        setShowModalNewDocumentChat: (value: boolean) => void;
        setShowModalActiveSession: (value: boolean) => void;
    };
};

const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    chatPageModals: {
        showModalNewDocumentChat: false,
        showModalActiveSession: false,
        setShowModalActiveSession: (value: boolean) =>
            set(
                produce((draf: Slice) => {
                    draf.chatPageModals.showModalActiveSession = value;
                    if (value) {
                        draf.chatPageModals.showModalNewDocumentChat = false;
                    }
                })
            ),
        setShowModalNewDocumentChat: (value: boolean) =>
            set(
                produce((draf: Slice) => {
                    draf.chatPageModals.showModalNewDocumentChat = value;
                    if (value) {
                        draf.chatPageModals.showModalActiveSession = false;
                    }
                })
            ),
    },
});

export { createSlice, type Slice };

import { inTeams } from '@shared/teams/teamsHelpers';
import { useEffect } from 'react';
import { useStore } from '@store';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const useHostedInTeams = (appInsights: ApplicationInsights) => {
    const { isHostedInTeams, setIsHostedInTeams } = useStore(state => state.teams);

    const createTrackEnvironmentEvent = (isHostedInTeams: boolean) => {
        try {
            const environment = isHostedInTeams ? 'teams' : 'browser';
            appInsights.trackEvent({ name: 'app_environment', properties: { environment } });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(`Unable to log 'app_environment' event to app insights. Reason: ${error}`);
        }
    };

    const calculateIsHostedInTeams = async () => {
        const inTeamsResult = await inTeams();
        setIsHostedInTeams(inTeamsResult);
        createTrackEnvironmentEvent(inTeamsResult);
    };

    useEffect(() => {
        calculateIsHostedInTeams();
    }, []);

    return isHostedInTeams;
};

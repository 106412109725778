import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { getAppSettings, msalSettings } from '@features/appSettings';
import { msalSetup } from '@features/authentication';
import '@features/internationalization/i18n';
import { WalkMe } from '@features/walkMe';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './App';
import { initializeGoogleAnalytics } from '@features/googleAnalytics/googleAnalytics';

const appSettings = await getAppSettings();

const msalInstance = new PublicClientApplication(msalSettings(appSettings.auth));
msalSetup(msalInstance, appSettings.auth);

if (appSettings.googleAnalytics.id) {
    initializeGoogleAnalytics(appSettings.googleAnalytics.id);
}

const root = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(root).render(
    <StrictMode>
        <HelmetProvider>
            <WalkMe url={appSettings.walkMe?.url} />
        </HelmetProvider>
        <MsalProvider instance={msalInstance}>
            <App publicClientApplication={msalInstance} settings={appSettings} />
        </MsalProvider>
    </StrictMode>
);

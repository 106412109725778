import { PropsWithChildren } from 'react';
import { useStore } from '@store';

type Props = {
    RefreshVersionPage: React.JSX.Element;
};

const VersionGuard = ({ RefreshVersionPage, children }: Props & PropsWithChildren) => {
    const isVersionChanged = useStore(state => state.versioning.isVersionChanged);
    const cacheVersion = sessionStorage.getItem('version');

    return <>{!isVersionChanged || !cacheVersion ? children : RefreshVersionPage}</>;
};
export { VersionGuard };

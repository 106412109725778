import { isFeatureFlagEnabled } from '@shared/featureFlag';

enum NesGptLogoSize {
    SMALL = 'small',
    LARGE = 'large',
    EXTRA_LARGE = 'extra-large',
}

const startAnniversaryDate = new Date(2024, 4, 20);
const endAnniversaryDate = new Date(2024, 4, 24, 23, 59);

const isTodayNesGptAnniversary = () => {
    const today = new Date();

    return (
        (startAnniversaryDate.getTime() <= today.getTime() && endAnniversaryDate.getTime() >= today.getTime()) ||
        isFeatureFlagEnabled('anniversary')
    );
};

export { startAnniversaryDate, endAnniversaryDate, isTodayNesGptAnniversary, NesGptLogoSize };

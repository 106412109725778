import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { FileSettings as DocSettings } from '../domain';

export type Slice = {
    fileSettings: {
        size: string | null;
        types: string | null;
        number: string | null;
        setFileSettings: (fileSettings: DocSettings) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    fileSettings: {
        size: null,
        types: null,
        number: null,
        setFileSettings: (fileSettings: DocSettings) =>
            set(
                produce((draft: Slice) => {
                    draft.fileSettings.size = fileSettings.size;
                    draft.fileSettings.types = fileSettings.types;
                    draft.fileSettings.number = fileSettings.number;
                })
            ),
    },
});

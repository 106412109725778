import { produce } from 'immer';
import { NIL as NIL_UUID } from 'uuid';
import { StateCreator } from 'zustand';
import { ChatGptClientEventMessage, ChatGptClientMessage, KindType, Temperature, AssistantIdentifier, MessageFeedback, ChatGptClientMessageMessage } from '../domain';
import { SSE } from 'sse.js';

export type Slice = {
    currentConversation: {
        messages: ChatGptClientMessage[];
        tools?: string[];
        id: string;
        etag?: string;
        isLoadingConversation: boolean;
        conversationHistoryReloadRequired: boolean;
        readonly: boolean;
        kind: KindType;
        fileName: string;
        fileId: string;
        temperature: Temperature;
        assistantIds: AssistantIdentifier[];
        temperatureChanged: boolean;
        addMessage: (message: ChatGptClientMessage) => void;
        clearMessages: () => void;
        setId: (id: string) => void;
        setConversation: (
            id: string,
            messages: ChatGptClientMessage[],
            etag: string,
            isReadonly: boolean,
            kind: KindType,
            fileName: string,
            temperature: Temperature,
            assistantIds: AssistantIdentifier[]
        ) => void;
        setConversationTools: (tools: string[]) => void;
        completeAllMessages: () => void;
        updateMessages: (messages: ChatGptClientMessage[]) => void;
        updateMessage: (messageIndex: number, feedBack: MessageFeedback) => void;
        updateConversationId: (id: string) => void;
        updateEtag: (etag: string) => void;
        setIsLoadingConversation: (value: boolean) => void;
        setConversationHistoryReloadRequired: (value: boolean) => void;
        setKind: (kind: KindType) => void;
        setFileId: (value: string) => void;
        setFileName: (value: string) => void;
        setTemperature: (value: Temperature) => void;
        cleanEmptyMessage: () => void;
        stopStreamingCall: () => void;
        streamingCall?: SSE;
        setStreamingCall: (sse: SSE) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    currentConversation: {
        messages: [],
        id: NIL_UUID,
        etag: '',
        isLoadingConversation: false,
        conversationHistoryReloadRequired: false,
        readonly: false,
        kind: 'standard',
        fileName: '',
        fileId: NIL_UUID,
        temperature: 'balanced',
        temperatureChanged: false,
        assistantIds: [],
        tools: ['model'],
        cleanEmptyMessage: () =>
            (() => {
                set(
                    produce((draft: Slice) => {
                        draft.currentConversation.messages = draft.currentConversation.messages.filter(
                            (message: ChatGptClientMessage): boolean =>
                                !(message.role === 'assistant' && message.streaming === true && !message.content)
                        );
                    })
                );
            })(),
        addMessage: (message: ChatGptClientMessage) =>
            (() => {
                set(
                    produce((draft: Slice) => {
                        if (message.role === 'event' && message.eventType === 'temperatureChange') {
                            draft.currentConversation.temperatureChanged = false;
                        }

                        draft.currentConversation.messages.push(message);
                    })
                );
            })(),

        clearMessages: () =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.messages = [];
                })
            ),
        setId: (id: string) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.id = id;
                })
            ),
        setConversation: (
            id: string,
            messages: ChatGptClientMessage[],
            etag: string,
            readonly: boolean,
            kind: KindType,
            fileName: string,
            temperature: Temperature,
            assistantIds: AssistantIdentifier[]
        ) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.id = id;
                    draft.currentConversation.messages = messages;
                    draft.currentConversation.etag = etag;
                    draft.currentConversation.readonly = readonly;
                    draft.currentConversation.kind = kind;
                    draft.currentConversation.fileName = fileName;
                    draft.currentConversation.temperature = temperature;
                    draft.currentConversation.assistantIds =
                        assistantIds.length === 0
                            ? [{ id: 'nesgpt', providerId: 'nesgpt', name: 'NesGPT' }]
                            : assistantIds;
                })
            ),
        completeAllMessages: () => {
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.messages = draft.currentConversation.messages.map(
                        (msg: ChatGptClientMessage) => ({
                            ...msg,
                            completed: true,
                        })
                    );
                })
            );
        },
        updateMessage: (messageIndex: number, feedback: MessageFeedback) => {
            set(
                produce((draft: Slice) => {
                    const message = draft.currentConversation.messages[messageIndex];

                    if ((message as ChatGptClientMessageMessage).feedback !== undefined) {
                      const msg = message as ChatGptClientMessageMessage;
                      msg.feedback = feedback;
                    }
                })
            )
        },
        updateMessages: (messages: ChatGptClientMessage[]) => {
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.messages = messages.map((msg: any, index: number) => {
                        if (index === messages.length - 1) {
                            return {
                                ...msg,
                                completed: false,
                            };
                        }
                        return msg;
                    });
                })
            );
        },
        updateConversationId: (id: string) => {
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.id = id;
                })
            );
        },
        updateEtag: (etag?: string) => {
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.etag = etag;
                })
            );
        },
        setConversationTools: (tools: string[]) =>
            set(
                produce((draft: Slice) => {
                    const currentTools = draft.currentConversation.tools || [];
                    const uniqueTools = Array.from(new Set([...currentTools, ...tools]));
                    draft.currentConversation.tools = uniqueTools;
                })
            ),
        setIsLoadingConversation: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.isLoadingConversation = value;
                })
            ),
        setConversationHistoryReloadRequired: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.conversationHistoryReloadRequired = value;
                })
            ),
        setKind: (kind: KindType) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.kind = kind;
                })
            ),
        setFileId: (value: string) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.fileId = value;
                })
            ),
        setFileName: (value: string) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.fileName = value;
                })
            ),
        setTemperature: (value: Temperature) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.temperature = value;

                    const temperatureMessages = draft.currentConversation.messages.filter(
                        m => m.role === 'event' && m.eventType === 'temperatureChange'
                    ) as ChatGptClientEventMessage[];

                    draft.currentConversation.temperatureChanged =
                        !!temperatureMessages.length &&
                        temperatureMessages[temperatureMessages.length - 1]?.eventValue.currentTemperature !== value;
                })
            ),
        setStreamingCall: (sse: SSE) =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.streamingCall = sse;
                })
            ),

        stopStreamingCall: () =>
            set(
                produce((draft: Slice) => {
                    draft.currentConversation.streamingCall?.close();

                    const lastMessageIndex = draft.currentConversation.messages.length - 1;
                    const lastMessage = draft.currentConversation.messages[lastMessageIndex];

                    if (lastMessage?.role === 'assistant' && !lastMessage.content.length) {
                        draft.currentConversation.messages.pop();
                    } else {
                        draft.currentConversation.messages[lastMessageIndex] = {
                            ...draft.currentConversation.messages[lastMessageIndex],
                            streaming: false,
                        } as ChatGptClientMessage;
                    }
                })
            ),
    },
});

import { Service } from '@features/chat/domain';
import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    service: {
        currentService: Service;
        maxTokens: number;
        setCurrentService: (value: Service, maxTokens: number) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    service: {
        currentService: 'AzChatGpt',
        maxTokens: 8192,
        setCurrentService: (value: Service, maxTokens: number) => {
            set(
                produce((draft: Slice) => {
                    draft.service.currentService = value;
                    draft.service.maxTokens = maxTokens;
                })
            );
        },
    },
});

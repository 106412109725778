import { AuthenticationResult, IPublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { MeInformationResponse } from '@features/authorization';
import { agent } from '@shared/api';
import { AuthRequest } from './domain';
import { ensureTeamsSdkInitialized, inTeams } from '@shared/teams/teamsHelpers';
import { authentication as teamsAuth } from '@microsoft/teams-js';

const PHOTO_KEY = 'photo';
const PERMISSIONS_KEY = 'permissions';

export const executeTokenAcquisition = async (instance: IPublicClientApplication, authRequest: AuthRequest) => {
    if (await inTeams()) {
        return executeTeamsTokenAcquisition();
    } else {
        return executeMsalTokenAcquisition(instance, authRequest);
    }
};

const executeTeamsTokenAcquisition = async () => {
    try {
        await ensureTeamsSdkInitialized();
        const accessToken = await teamsAuth.getAuthToken();

        return { account: null, accessToken: accessToken };
    } catch (error) {
        console.error(`Unable to acquire the Teams access token. Reason: ${error}`);
        return null;
    }
};

const executeMsalTokenAcquisition = async (instance: IPublicClientApplication, authRequest: AuthRequest) => {
    const account = instance.getActiveAccount() ?? undefined;
    const scopes = authRequest.scopes;

    try {
        const response: AuthenticationResult = await instance.acquireTokenSilent({ account, scopes });

        return { account: response.account, accessToken: response.accessToken };
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await instance.loginRedirect(authRequest);
        } else {
            console.error(`Unable to acquire Msal token. Reason: ${error}`);
        }

        return null;
    }
};

export const executeMeInfo = async (setIsLoadingMeInfo?: (value: boolean) => void) => {
    try {
        let savedPhoto = sessionStorage.getItem(PHOTO_KEY);
        let savedPermissions = sessionStorage.getItem(PERMISSIONS_KEY);
        if (!savedPhoto || !savedPermissions) {
            const response = await agent.get<MeInformationResponse>('/api/me');
            sessionStorage.setItem(PHOTO_KEY, response.photo);
            savedPhoto = response.photo;
            savedPermissions = JSON.stringify(response.permissions);
            sessionStorage.setItem(PERMISSIONS_KEY, JSON.stringify(response.permissions));
        }

        setIsLoadingMeInfo && setIsLoadingMeInfo(false);
        return { userPhoto: savedPhoto, permissions: JSON.parse(savedPermissions) };
    } catch (error) {
        console.warn(`Unable to get me data. Reason: ${error}`);
        setIsLoadingMeInfo && setIsLoadingMeInfo(false);
        return null;
    }
};

import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    teams: {
        setIsHostedInTeams: (value: boolean) => void;
        isHostedInTeams: boolean | null;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    teams: {
        isHostedInTeams: null,
        setIsHostedInTeams: (value: boolean) =>
            set(
                produce((draft: Slice) => {
                    draft.teams.isHostedInTeams = value;
                })
            ),
    },
});

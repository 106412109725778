import { IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from '@features/authentication';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
    publicClientApplication: IPublicClientApplication;
    children: ReactElement;
};

const ClientSideNavigation = ({ publicClientApplication, children }: Props) => {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    publicClientApplication.setNavigationClient(navigationClient);

    const [firstRender, setFirstRender] = useState(true);
    useEffect(() => {
        setFirstRender(false);
    }, []);

    if (firstRender) {
        return null;
    }

    return children;
};

export { ClientSideNavigation };

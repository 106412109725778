import { ReactComponent as RefreshPageIcon } from '@assets/icons/refresh.svg';
import { ReactComponent as RefreshPageImage } from '@assets/images/refreshPage.svg';
import { useVersion } from '@features/version';
import { Button, NesGptLogo } from '@shared/ui';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const RefreshPage = () => {
    const { t } = useTranslation();
    const { updateCacheVersion } = useVersion();

    const onClick = () => {
        updateCacheVersion();
        window.location.reload();
    };

    return (
        <>
            <MobileView>
                <div className="w-full h-screen flex flex-col justify-center text-center">
                    <div className="w-full flex justify-center">
                        <RefreshPageImage className="w-[200px] h-[200px]" />
                    </div>
                    <div className="p-4 flex flex-col items-center">
                        <h2 className="text-[70px] text-primary-400 leading-[98px] font-bold mb-4">
                            {t('refreshPage.warning')}
                        </h2>
                        <h3 className="text-2xl mb-2 font-bold">{t('refreshPage.title')}</h3>
                        <p className="text-xl mb-11" data-testid="refresh-page-text-content">
                            {t('refreshPage.subtitle')}
                        </p>
                        <Button onClick={onClick} className="btn btn-md w-4/6">
                            <span className="text-xl">{t('refreshPage.cta')}</span>
                            <RefreshPageIcon />
                        </Button>
                    </div>
                </div>
            </MobileView>
            <BrowserView>
                <div className="w-full h-screen flex flex-col justify-center items-center">
                    <NesGptLogo className="mb-12" />
                    <div className="flex justify-start items-center gap-6 max-w-2xl">
                        <div className="w-1/2">
                            <h2 className="text-[108px] text-primary-400 leading-[98px] font-bold mb-4">
                                {t('refreshPage.warning')}
                            </h2>
                            <h3 className="text-2xl mb-2 font-bold">{t('refreshPage.title')}</h3>
                            <p className="text-xl mb-11" data-testid="refresh-page-text-content">
                                {t('refreshPage.subtitle')}
                            </p>
                            <Button onClick={onClick} className="btn btn-md w-4/6">
                                <span className="text-xl">{t('refreshPage.cta')}</span>
                                <RefreshPageIcon />
                            </Button>
                        </div>
                        <div className="w-1/2">
                            <RefreshPageImage />
                        </div>
                    </div>
                </div>
            </BrowserView>
        </>
    );
};

export { RefreshPage };

import { Service } from '@features/chat/domain';

export const NesGptAssistantId = 'nesgpt';
export const NesGptAssistantProviderId = 'nesgpt';

type AssistantIdentifier = {
    id: string;
    providerId: string;
};

type AssistantPreview = {
    assistantId: string;
    assistantProviderId: string;
    name: string;
    oneLinerDescription: string;
    imageUrl: string;
};

type AssistantPreviewPromptSuggestionText = {
    highlightedWord: string;
    variant: PromptSuggestionVariantType.text;
} & BasePromptSuggestion;

type AssistantPreviewPromptSuggestionCitation = {
    citation: string;
    variant: PromptSuggestionVariantType.citation;
} & BasePromptSuggestion;

type AssistantPreviewPromptSuggestionTag = {
    icon: string;
    tagType: PromptSuggestionTagType;
    variant: PromptSuggestionVariantType.tag;
} & BasePromptSuggestion;

type BasePromptSuggestion = {
    title: string;
    text: string;
};

type AssistantPreviewPromptSuggestion =
    | AssistantPreviewPromptSuggestionTag
    | AssistantPreviewPromptSuggestionCitation
    | AssistantPreviewPromptSuggestionText;

type AssistantPreviewPromptingGuide = {
    id: string;
    title: string;
    suggestions: AssistantPreviewPromptSuggestion[];
};

type Assistant = {
    assistantId: string;
    assistantProviderId: string;
    name: string;
    description: string;
    oneLinerDescription: string;
    onboardingText: string;
    imageUrl: string;
    supportLink: string;
    supportText: string;
    privacyPolicyLink: string;
    knowledgeBase: { link: string; text: string }[];
    category: string;
    configuration: AssistantConfiguration;
    promptGuide: AssistantPreviewPromptingGuide[];
};

type AssistantSelection = {
    assistantId: string;
    assistantProviderId: string;
};

type AssistantConfiguration = {
    isChatWithYourDocumentsAllowed: boolean;
    temperaturesAllowed: string[];
    modelsAllowed: {
        service: Service;
        maxTokens: number;
    }[];
};

export enum PromptSuggestionTagType {
    internet,
    internal,
}

export enum PromptSuggestionVariantType {
    text,
    citation,
    tag,
}

export const isNesGPTAssistant = (assistant: AssistantIdentifier) =>
    assistant.id === NesGptAssistantId && assistant.providerId === NesGptAssistantProviderId;

export type {
    AssistantIdentifier,
    AssistantPreview,
    Assistant,
    AssistantSelection,
    AssistantConfiguration,
    AssistantPreviewPromptingGuide,
    AssistantPreviewPromptSuggestionText,
    AssistantPreviewPromptSuggestionCitation,
    AssistantPreviewPromptSuggestionTag,
};

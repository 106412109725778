import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { FeatureFlags } from '../domain';

const defaultState: FeatureFlags = {
    unifiedBots: false,
    streaming: false,
    nesgptAvailable: false,
    feedbackShare: false,
    typeWriterDisabled: false,
    promptLibrary: false,
};

export type Slice = {
    featureFlags: {
        flags: FeatureFlags;
        setFeatureFlags: (flags: FeatureFlags) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    featureFlags: {
        flags: defaultState,
        setFeatureFlags: (featureFlags: FeatureFlags) =>
            set(
                produce((draft: Slice) => {
                    draft.featureFlags.flags = { ...draft.featureFlags.flags, ...featureFlags } || defaultState;
                })
            ),
    },
});

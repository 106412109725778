import { useStore } from '../../useStore';

const ManageCurrentVersion = (response: Response) => {
    const setIsVersionChanged = useStore.getState().versioning.setIsVersionChanged;
    const setVersion = useStore.getState().versioning.setVersion;
    const cacheVersion = sessionStorage.getItem('version');
    const headerVersion = response.headers.get('X-version') || '';

    if (!cacheVersion) {
        sessionStorage.setItem('version', headerVersion);
    } else if (cacheVersion !== headerVersion && response.status === 200) {
        setIsVersionChanged(true);
        setVersion(headerVersion);
    }
};

export { ManageCurrentVersion };

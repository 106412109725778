import { createSlice as createFileSettingsSlice, Slice as FileSettingsSlice } from '@features/appSettings/files/slice';
import { createSlice as createInsightsSlice, Slice as InsightsSlice } from '@features/appSettings/insights/slice';
import { createSlice as createLinksSlice, Slice as LinksSlice } from '@features/appSettings/links/slice';
import { createSlice as createAuthSlice, Slice as AuthSlice } from '@features/authentication/slice';
import { createSlice as createChaPageModals, Slice as ChaPageModalsSlice } from '@features/chat/chatPageModals/slice';
import {
    createSlice as createModelSelectorSlice,
    Slice as ModelSelectorSlice,
} from '@features/chat/chatSideBar/sections/modelSelector/slice';
import {
    createSlice as createChattingWithMyDocsSlice,
    Slice as ChattingWithMyDocsSlice,
} from '@features/chat/chatSideBar/sections/talkToMyDocuments/slice';
import { createSlice as createToolBarSlice, Slice as ToolBarSlice } from '@features/chat/chatSideBar/slice';
import { createSlice as createConversation, Slice as ConversationSlice } from '@features/chat/conversation/slice';
import { createSlice as createNewConversation, Slice as NewConversationSlice } from '@features/chat/new/slice';
import { createSlice as featureFlagSlice, Slice as FeatureFlagSlice } from '@features/appSettings/featureFlags/slice';
import { Slice as ChatQuestionSlice, createSlice as createChatQuestion } from '@features/chat/slice';
import { Slice as VersioningSlice, createSlice as versioning } from '@features/version/slice';
import { FilterSlice, createSlice as filter } from '@features/chat/filter/slice';
import { HistoryChatSlice, createSlice as history } from '@features/history/slice';
import { Slice as AssistantsSlice, createSlice as createAssistants } from '@features/assistants/slice';
import { createSlice as createTeamsSlice, Slice as TeamsSlice } from '@features/teams/slice';

import { create, StoreApi } from 'zustand';
import { devtools } from 'zustand/middleware';

export type StoreState = AuthSlice &
    LinksSlice &
    FileSettingsSlice &
    ChattingWithMyDocsSlice &
    ModelSelectorSlice &
    ConversationSlice &
    ToolBarSlice &
    ChaPageModalsSlice &
    NewConversationSlice &
    InsightsSlice &
    VersioningSlice &
    ChatQuestionSlice &
    FilterSlice &
    FeatureFlagSlice &
    HistoryChatSlice &
    AssistantsSlice &
    TeamsSlice;

export type StoreSlice<T> = (set: StoreApi<T>['setState'], get: StoreApi<T>['getState']) => T;

export const useStore = create<StoreState>()(
    devtools((...all) => ({
        ...createAuthSlice(...all),
        ...createLinksSlice(...all),
        ...createFileSettingsSlice(...all),
        ...createChattingWithMyDocsSlice(...all),
        ...createModelSelectorSlice(...all),
        ...createConversation(...all),
        ...createToolBarSlice(...all),
        ...createChaPageModals(...all),
        ...createNewConversation(...all),
        ...createInsightsSlice(...all),
        ...createChatQuestion(...all),
        ...versioning(...all),
        ...featureFlagSlice(...all),
        ...filter(...all),
        ...history(...all),
        ...createAssistants(...all),
        ...createTeamsSlice(...all),
    }))
);

import { PropsWithChildren } from 'react';
import { useStore } from '@store';
import { ErrorPage } from '@pages/ErrorPage';

const MaintenanceGuard = ({ children }: PropsWithChildren) => {
    const { nesgptAvailable } = useStore(store => store.featureFlags.flags);

    if (!nesgptAvailable) {
        return <ErrorPage variant={'nesgpt-unavailable-generic'} />;
    }

    return <>{children}</>;
};

export default MaintenanceGuard;

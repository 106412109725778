import { produce } from 'immer';
import { StateCreator } from 'zustand';

export type Slice = {
    insights: {
        key: string | null;
        setKey: (key: string) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    insights: {
        key: null,
        setKey: (key: string) =>
            set(
                produce((draft: Slice) => {
                    draft.insights.key = key;
                })
            ),
    },
});

import en from '@assets/locals/en.json';
import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const Language = {
    English: 'en-US',
} as const;

const resources = {
    [Language.English]: {
        translation: en,
    },
};

const createI18n = (language: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);
    i18n.init({
        resources,
        debug: false,
        lng: language,
        fallbackLng: language,
        returnNull: false,
        interpolation: { escapeValue: false },
    });
    return i18n;
};

export const i18n = createI18n(Language.English);

import { ReactComponent as ErrorPageImage } from '@assets/images/errorPage.svg';
import { ReactComponent as ServiceUnavailableImage } from '@assets/images/serviceUnavailable.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/icons/arrowRight.svg';
import { FunctionComponent, SVGProps } from 'react';
import { useStore } from '@store';

type ErrorPageVariant = 'default' | 'nesgpt-unavailable-goto-teams' | 'nesgpt-unavailable-generic';

type ErrorPageResource = {
    title: string;
    subtitle: string;
    ctaText: string;
    ctaLink: string | (() => string);
    ctaIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
};

const ErrorPageResources: { [key in ErrorPageVariant]: ErrorPageResource } = {
    default: {
        title: 'errorPage.default.title',
        subtitle: 'errorPage.default.subtitle',
        ctaText: 'errorPage.default.cta',
        ctaLink: '/',
        icon: ErrorPageImage,
    },
    'nesgpt-unavailable-goto-teams': {
        title: 'errorPage.nesgpt-unavailable-goto-teams.title',
        subtitle: 'errorPage.nesgpt-unavailable-goto-teams.subtitle',
        ctaText: 'errorPage.nesgpt-unavailable-goto-teams.cta',
        ctaLink: () => useStore.getState().links.teamsBotRedirection || '',
        ctaIcon: ArrowRightIcon,
        icon: ServiceUnavailableImage,
    },
    'nesgpt-unavailable-generic': {
        title: 'errorPage.nesgpt-unavailable-generic.title',
        subtitle: 'errorPage.nesgpt-unavailable-generic.subtitle',
        ctaText: 'errorPage.nesgpt-unavailable-generic.cta',
        ctaLink: '/',
        icon: ServiceUnavailableImage,
    },
};

export { ErrorPageResources };

export type { ErrorPageVariant };

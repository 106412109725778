import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useStore } from '@store';
import { useEffect, useState } from 'react';
import { executeMeInfo, executeTokenAcquisition } from './api';

export const useExecuteAuthenticationFlow = () => {
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { setUser, user } = useStore(state => state.auth);
    const { isHostedInTeams } = useStore(state => state.teams);

    const [isLoadingMeInfo, setIsLoadingMeInfo] = useState(true);

    const authenticationFlow = async () => {
        const tokenAcquisition = await executeTokenAcquisition(instance, user.authRequest);
        setUser({
            ...user,
            accessToken: tokenAcquisition?.accessToken || '',
            account: tokenAcquisition?.account || null,
        });

        const meInfo = await executeMeInfo(setIsLoadingMeInfo);
        setUser({
            ...user,
            accessToken: tokenAcquisition?.accessToken || '',
            account: tokenAcquisition?.account || null,
            userPhoto: meInfo?.userPhoto || '',
            permissions: meInfo?.permissions || [],
        });
    };

    useEffect(() => {
        if (isAuthenticated || isHostedInTeams) {
            authenticationFlow();
        }
    }, [isAuthenticated, isHostedInTeams]);

    return { isLoadingMeInfo };
};

import { produce } from 'immer';
import { StateCreator } from 'zustand';
import { LinkSettings } from '../domain';

export type Slice = {
    links: {
        usageGuidance: string | null;
        tokenCalculation: string | null;
        modelsGuidance: string | null;
        promptGuidelines: string | null;
        nesGPTFAQs: string | null;
        nesGPTDocs: string;
        support: string | null;
        teamsBotRedirection: string | null;
        talkToMyDocumentsGuidance: string | null;
        setLinkSettings: (linkSettings: LinkSettings) => void;
    };
};

export const createSlice: StateCreator<Slice, [], [], Slice> = set => ({
    links: {
        usageGuidance: null,
        tokenCalculation: null,
        modelsGuidance: null,
        promptGuidelines: null,
        nesGPTFAQs: null,
        nesGPTDocs: '',
        support: null,
        teamsBotRedirection: null,
        talkToMyDocumentsGuidance: null,
        setLinkSettings: (linkSettings: LinkSettings) =>
            set(
                produce((draft: Slice) => {
                    draft.links.usageGuidance = linkSettings.usageGuidance;
                    draft.links.tokenCalculation = linkSettings.tokenCalculation;
                    draft.links.modelsGuidance = linkSettings.modelsGuidance;
                    draft.links.nesGPTDocs = linkSettings.nesGPTDocs;
                    draft.links.teamsBotRedirection = linkSettings.teamsBotRedirection;
                    draft.links.talkToMyDocumentsGuidance = linkSettings.talkToMyDocumentsGuidance;
                })
            ),
    },
});

import { useExecuteAuthenticationFlow } from '@features/authentication';
import { AuthSettings } from '@features/appSettings';
import { LazyChatPage, LazyJsonToMarkdown } from '@pages/lazyLoadingPages';
import { Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Routes } from 'react-router-dom';
import { RouteGuard } from './RouteGuard';
import { SkeletonLoader } from '@shared/ui/loaders';
import classNames from 'classnames';
import { isTodayNesGptAnniversary } from '@shared/ui/nesgptLogo/domain';
import { RefreshPage } from '@pages/RefreshPage';
import { VersionGuard } from '@features/version';
import MaintenanceGuard from '@features/maintenance/MaintenanceGuard';

type Props = {
    authSettings: AuthSettings;
};

const ApplicationRoutes = ({ authSettings }: Props) => {
    const { isLoadingMeInfo } = useExecuteAuthenticationFlow();
    const isTodayNestleAnniversary = isTodayNesGptAnniversary();

    return (
        <Routes>
            <Route
                element={
                    <Suspense
                        fallback={
                            <div
                                className={classNames('w-full h-screen flex justify-center items-center', {
                                    'sm:-mt-[9.1rem]': isTodayNestleAnniversary && !isMobile,
                                    'sm:-mt-[9.4rem]': !isTodayNestleAnniversary && !isMobile,
                                    'mt-0': isMobile,
                                })}
                            >
                                <SkeletonLoader />
                            </div>
                        }
                    >
                        <RouteGuard scopes={authSettings.scopes} isLoadingMeInfo={isLoadingMeInfo} />
                    </Suspense>
                }
            >
                <Route index element={<MainContent />} />
                <Route path="/jsonToMarkdown" element={<LazyJsonToMarkdown />} />
            </Route>
        </Routes>
    );
};

const MainContent = () => (
    <MaintenanceGuard>
        <VersionGuard RefreshVersionPage={<RefreshPage />}>
            <LazyChatPage />
        </VersionGuard>
    </MaintenanceGuard>
);

export { ApplicationRoutes };
